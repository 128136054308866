@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      div::-webkit-scrollbar {
        display: none;
      }
      div {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }
  }
