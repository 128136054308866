@keyframes background-primary-color-anim {
    from {
      background-color: #1278fb;
      color: #f6faff;
    }
  
    to {
      background-color: #f6faff;
      color: #1278fb;
    }
  }
  
  .primary-color-animate {
    animation: background-primary-color-anim 1s linear infinite alternate;
  }
  
  @keyframes background-secondary-color-anim {
    from {
      background-color: #fb9512;
      color: #fff;
    }
  
    to {
      background-color: #fff;
      color: #fb9512;
    }
  }
  
  .secondary-color-animate {
    animation: background-secondary-color-anim 1s linear infinite alternate;
  }